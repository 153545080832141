export enum TYPE_MAIN_NOTIFICACION {
  INDEXTEST = 0,
  REGISTRO = 1,
  RECUPERAR_PASS = 2,
  CONFIRMA_PEDIDO = 3,
  CODE_VER_WHATSAPP = 4,
  CANCELADO_PEDIDO = 5,
  ARMADO_PEDIDO = 6,
  PREPARADO_PEDIDO = 7,
  REGISTRO_ACTIVO = 8,
  REGISTRO_PENDIENTE_APROBADO = 9,
  REGISTRO_APROBADO = 10
}

export enum TIPO_DE_ORDEN_DE_LISTA {
  POR_FECHA_MENOR = 0,
  POR_FECHA_MAYOR = 1,
  POR_PRECIO_MENOR = 2,
  POR_PRECIO_MAYOR = 3,
  MAS_RELEVANTES_ORDEN_CUSTOM = 4
}
export enum TYPE_SITEFEATURES {
  IMAGE_MARKER = 0,
  YOUTUBE_PRODUCT = 1,
  YOUTUBE_LANDING = 2,
  INTEGRACION_API_ENVIOS = 3,
  INTEGRACION_API_PAGOS = 4,
  LISTAS_CUSTOMIZADAS = 5
}
export enum TYPE_ACCOUNT {
  CLIENTE = 0,
  ADMINISTRADOR = 1,
  GOD = 2,
  UNKNOWN = 3
}
export enum TYPE_CONTENIDO_BARCARSLI {
  BANNER = 0,
  CARROUSEL = 1,
  SLIDER = 2,
  OTRO = 3
}
export enum ESTADO_PEDIDO {
  COMPRANDO = 0,
  NUEVO = 1,
  CONFIRMADO = 2,
  COMPLETADO = 3,
  CANCELADO = 4
}
export enum EXCLUSIVIDAD {
  Normal = 0,
  Exclusivo = 1,
  Exclusivo_VIP = 2
}

export enum ESTADO_PRODUCTO {
  EN_STOCK = 0,
  SIN_STOCK = 1,
  DESACTIVADO = 2,
  RECHAZA_COMPRA = 3,
  CANCELADO = 4
}
export enum ESTADO_INTERNO_PEDIDO {
  SIN_PROCESAR = 0,
  CONTACTADO = 1,
  PREPARADO = 2,
  ARMADO = 3,
  RECHAZAR = 4,
  CANCELADO = 5,
  IMPORTADO_COBRANZA = 6,
  COBRANZA_SIN_PROCESAR = 7
}
export enum TYPE_CONTENIDO_HIT {
  PRODUCTO = 0,
  BANNER = 1,
  CARROUSEL = 2,
  SLIDER = 3,
  OTRO = 4
}
export enum OPERATION_TYPE {
  SUCCESS_LOGIN = 0,
  FAIL_LOGIN = 1,
  ERROR = 2,
  UNKNOWN = 3,
  NEW_RECORD = 4,
  DEL_RECORD = 5,
  UPD_RECORD = 6,
  RESET_PASSWORD = 7,
  CHANGE_PEDIDO = 8,
  NUEVO_PEDIDO = 9,
  CANCELAR_PEDIDO = 10,
  GET_SENSIBLE_DATA_DEF1 = 11,
  GET_SENSIBLE_DATA_DEF2 = 12,
  GET_SENSIBLE_DATA_DEF3 = 13,
  GET_SENSIBLE_DATA_DEF4 = 14,
  GET_SENSIBLE_DATA_DEF5 = 15,
  ACCOUNT_DELETE_REQUEST = 16,
  PAYMENT_ACCEPTED = 17,
  PAYMENT_FAILED = 18,
  PREFERENCE_CREATED = 19
}
export enum CRITERIO_RECARGO {
  SIN_RECARGO = 0,
  POR_PORCENTAJE = 1,
  POR_MONTOFIJO = 2
}
export enum INTEGRACION_API_ENVIOS {
  SIN_INTEGRACION = 0,
  MI_CORREO = 1
  // PAQAR_CORREOARGENTINO = 2,
  // ANDREANI = 3,
  // PINPOINT = 4,
}
export enum INTEGRACION_API_PAGOS {
  SIN_INTEGRACION = 0,
  MERCADOPAGO_CKOAPI = 1
  // MERCADOPAGO_CKOPRO = 2,
}
export enum CONTENIDO_FORMATO {
  HORIZONTAL = 0,
  VERTICAL = 1,
  CUADRADO_SMALL = 2,
  CUADRADO_MEDIMUM = 3,
  CUADRADO_LARGE = 4,
  FULL_ANCHO = 5,
  FULL_SCREEN = 6,
  INVISIBLE = 7,
  FULL_RESPONSIVE = 8
}
export enum CONTENIDO_UBICACIONES {
  HOME_LANDING = 0,
  HOME_MIDDLE = 1,
  HOME_PREFOOTER = 2,
  PRODUCTO_LANDING = 3,
  PRODUCTO_MIDDLE = 4,
  PRODUCTOE_PREFOOTER = 5,
  LISTADO_LANDING = 6,
  LISTADO_MIDDLE = 7,
  LISTADO_PREFOOTER = 8,
  OTROS_LANDING = 9,
  OTROS_MIDDLE = 10,
  OTRO_PREFOOTER = 11,
  POPUP_HOME = 12,
  NO_APLICA = 13,

  CATEGORIA_HEADERMENU = 14,
  EXCLUSIVO_HEADERMENU = 15,

  MOBILE_MAIN_TOP_SLIDER = 16,
  MOBILE_MAIN_MID_SLIDER = 17,
  MOBILE_MAIN_BOTTOM_SLIDER = 18
}
export enum CRITERIO_IVA {
  NO_APLICA = 0,
  TODOS = 1,
  POR_PRODUCTO = 2
}
export enum TIPO_TRANSICION {
  NINGUNO = 0,
  FADE_IN = 1,
  FADE_OUT = 2,
  IZQ_DER = 3,
  DER_IZQ = 4,
  ARR_ABAJO = 5,
  ABA_ARRIBA = 6,
  FULL_SCREEN = 7,
  INVISIBLE = 8,
  FULL_RESPONSIVE = 9
}

export interface IImagesUploadResults {
  GUID: string;
  FilePath: string;
  Fecha: Date;
  OriginalFileName: string;
  Por3Sizes: boolean;
}
export enum TIPO_CONTRIBUYENTE {
  CONSUMIDOR_FINAL = 0,
  MONOTRIBUTO = 1,
  RESPONSABLE_INSCRIPTO = 2,
  EXENTO = 3,
  NO_RESPONSABLE = 4,
  RESPONSABLE_MONOTRIBUTO = 5,
  REGIMEN_GENERAL = 6,
  OTRO = 7
}
export enum TIPO_DOCUMENTO {
  DNI = 0,
  CUIT = 1,
  CUIL = 2,
  DNI_EXTRANJERO = 3,
  PASAPORTE = 4,
  LI_LE = 5,
  OTRO = 6
}
